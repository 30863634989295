const experinces = [
    {
        id: "1",
        companyName: "Yahma Import & Export Co. (China)",
        period: "2006-2015",
        position: "Business Manager & Owner",
        info: "For about 10 years, I had a company in China, where I collaborated and did business with many customers from different countries around the world. This job gave the chance to improve my skills in doing big deals with both factories and companies. I also learned a lot about logistics and warehousing.",
    },

    {
        id: "2",
        companyName: "Vaza Store (Syria)",
        period: "2008-2011",
        position: "Business Owner",
        info: "I had a store for selling crafts and gifts. I was responsible for purchasing goods and organizing the range of the products.",
    },

    {
        id: "3",
        companyName: "Internet Computer Center",
        period: "2002-2005",
        position: "IT department & Partner",
        info: "It was my first experience outside the family business. I was responsible for selling and fixing computers and other digital products. During this time, I could improve my digital skills and using different software and applications. I could also enhance my selling skills and techniques.",
    },
]

export default experinces;
