import React, { Component } from 'react';
import logo from '../../images/logo.svg';
import Slidebar from '../Menu/Component';


class Header extends Component {
    render() { 
        return (
            <div className="header">
                <img src={logo} alt="logo" className="logo" />
                <Slidebar className="nav" />
            </div>
        );
    }
}
 
export default Header;
