import React, { Component } from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithubSquare } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhoneSquare } from 'react-icons/fa';


class Footer extends Component {
    render() { 
        return (
            <div className="footer">
                <div className='container'>
                    <div className='row'>
                        <div className='socila'><a href="https://www.linkedin.com/in/mukhtar-sibai-580ab6177/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></div>
                        <div className='social'><a href="https://github.com/Msibai" target="_blank" rel="noopener noreferrer"><FaGithubSquare /></a></div>
                        <div className='social'><a href="mailto:mukhtarsibai@gmail.com"><FaEnvelope /></a></div>
                        <div className='social'><a href="tel:0046737013317"><FaPhoneSquare /></a></div>
                    </div>
                    <div className='copy'><p>© {new Date().getFullYear()} Mukhtar Sibai</p></div>
                </div>
            </div>
        );
    }
}
 
export default Footer;
