const projects = [
    {
        id: "1",
        name: "Xchange",
        imageName: "xchange",
        info: "A progressive web app created for exchange rates obtained from the European Central Bank's API as a school project. I built it with React and firebase.",
        url: "https://xchange-e88b9.firebaseapp.com/"
    },

    {
        id: "2",
        name: "KSPB",
        imageName: "kspb",
        info: "WordPress website for Kristinstads Plusbilar AB. A company that provide professional car care solutions",
        url: "https://www.kspb.se"
    }
]

export default projects;
