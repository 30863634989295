import React, { Component } from 'react';
import Scroll from 'react-scroll';
import './style.css';

const Link = Scroll.Link;

class ScrollDown extends Component {
    render() { 
        return (
            <div id="scrollDown" className="scroll">
                <Link
                    id="about"
                    to="about" 
                    spy={true}
                    hashSpy={true}
                    smooth={true}
                    duration={500} 
                    className='link' 
                    activeClass='some-active-class'
                >
                    <span></span>
                </Link>
            </div>

        );
    }
}
 
export default ScrollDown;
