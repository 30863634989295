import React, { Component } from 'react';


class TextArea extends Component {
    render() { 
        return (
            <div className="form-group">
                <textarea
                  id={this.props.id}
                  name={this.props.name}
                  className={this.props.className}
                  required={this.props.required}
                  rows={this.props.rows}
                  onChange={this.props.onChange}
                  value={this.props.value}
                  placeholder="Your Message"
                />
                <span className="error">{this.props.error}</span>
            </div>
        );
    }
}
 
export default TextArea;
