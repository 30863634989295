import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { Progress } from 'react-sweet-progress';
import skill from './data';
import "react-sweet-progress/lib/style.css";


class Skills extends Component {
    render() {
        const skillsList = skill.map((item, i) => {
            return(
                <div className="wrapper" key={i}>
                    <div className="skill-logo">
                        <img src={require(`../../images/${item.imageName}.svg`)} alt= {`${item.name} logo`}></img>                            
                    </div>
                    <div className="skill-details">
                        <div className="skill-meta">
                            <h3>{item.name}</h3>
                            <p>{item.period}</p>
                        </div>
                        <div className="skill-bar">
                            <Progress percent={item.percent} />
                        </div>
                    </div>
                </div>
            );

        });

        return (
            <React.Fragment>
                <Element id="skills" name="skills">
                    <section className="skills-section">
                        <div className="section-header">
                            <h2>SKILLS</h2>
                            <div className="separator"></div>
                        </div>
                        <div className="section-main">
                            {skillsList}
                        </div>
                    </section>
                </Element>
            </React.Fragment>
        );
    }
}

export default Skills;
