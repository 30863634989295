import React, { Component } from 'react';
import { Element } from 'react-scroll';
import myImage from '../../images/me.JPG';


class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Element id="about" name="about">
                    <section className="about-section">
                        <div className="section-header">
                            <h2>ABOUT</h2>
                            <div className="separator"></div>
                        </div>
                        <div className="section-main">
                            <div className="image">
                                <img src={myImage} alt="Mukhtar Sibai" />
                            </div>
                            <div className="info">
                                <h3>WHO I AM</h3>
                                <div className="separator"></div>
                                <p>
                                    I have always had a serious passion for coding and web development. Although my career path has taken many twists and turns 
                                    -started after high school as a computer technician and at the same time studied in the university in the field of simultaneous interpreting, 
                                    to an owner of an import and export company in China.- 
                                    I have never forgotten my passion for programming, so in 2018 I started studying front-end and E-Handel development at Malmö Yrkeshögskola in Sweden.
                                </p>
                                <p>
                                    I’m ambitious and driven. I always see problems and challenges as opportunities to learn something new and gain more experience and skills, 
                                    so I have something to strive towards. And that’s helped me to be creative and to be able to generate new ideas. 
                                </p>
                                <p>
                                    I’m organized in a systematic way. I always take notes, and I use a series of tools to help myself stay on top of deadlines. 
                                    I’m a team-oriented person who will get the job done. I find this increases efficiency and helps the rest of the team stay on track, too.
                                    I am passionate about my work. Because I love what I do, I have a steady source of motivation that drives me to do my best. 
                                </p>
                                <p className="signature">Mukhtar Sibai</p>
                            </div>
                        </div>
                    </section>
                </Element>
            </React.Fragment>
        );
    }
}
export default About;
