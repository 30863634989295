import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { ToastContainer } from 'react-toastify';
import ContactForm from './ContactForm';


class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <Element id="contact" name="contact">
                    <section className="contact-section">
                        <div className="section-header">
                            <h2>Contact</h2>
                            <div className="separator"></div>
                        </div>
                        <div className="section-main">
                            <p>Have a question or want to work together?</p>
                            <ToastContainer/>
                            <ContactForm />
                        </div>
                    </section>
                </Element>
            </React.Fragment>
        );
    }
}
 
export default Contact;
