import React, { Component } from 'react';
import { bubble as Menu } from 'react-burger-menu';
import { Link } from 'react-scroll';
import './Style.css'

class Slidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            menuOpen: false
         }
    }

    handleStateChange(state) {
        this.setState({menuOpen: state.isOpen});
    }

    closeMenu() {
        this.setState({menuOpen: false});
    }
    render() { 
        return (
            <Menu
              right
              isOpen={this.state.menuOpen}
              onStateChange={(state) => this.handleStateChange(state)}
            >
                <Link
                  onClick={() => this.closeMenu()}
                  id="menu-home"
                  to="home" 
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  duration={500} 
                  className='menu-item' 
                  activeClass='some-active-class'
                >
                    HOME
                </Link>  
                <Link
                  onClick={() => this.closeMenu()}
                  id="menu-about"
                  to="about" 
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  duration={500} 
                  className='menu-item' 
                  activeClass='some-active-class'
                >
                    ABOUT ME
                </Link> 
                <Link
                  onClick={() => this.closeMenu()}
                  id="menu-experience"
                  to="experience" 
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  duration={500} 
                  className='menu-item' 
                  activeClass='some-active-class'
                >
                    EXPERIENCE
                </Link>  
                <Link
                  onClick={() => this.closeMenu()}
                  id="menu-skills"
                  to="skills" 
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  duration={500} 
                  className='menu-item' 
                  activeClass='some-active-class'
                >
                    SKILLS
                </Link> 
                <Link
                  onClick={() => this.closeMenu()}
                  id="menu-portfolio"
                  to="portfolio" 
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  duration={500} 
                  className='menu-item' 
                  activeClass='some-active-class'
                >
                    PORTFOLIO
                </Link>  
                <Link
                  onClick={() => this.closeMenu()}
                  id="menu-contact"
                  to="contact" 
                  spy={true}
                  hashSpy={true}
                  smooth={true}
                  duration={500} 
                  className='menu-item' 
                  activeClass='some-active-class'
                >
                    CONTACT ME
                </Link>
                <a className="cv" href="https://drive.google.com/file/d/1z-NGfBoyuBxvbtpSP3VSTzvQS_fRjz3M/view?usp=sharing">C.V</a>      
            </Menu>
        );
    }
}
 
export default Slidebar;
