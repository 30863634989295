import React, { Component } from 'react';
import { Element } from 'react-scroll';
import ScrollDown from '../Scroll/Component';
import ReactTypingEffect from 'react-typing-effect';


const TypingEffect  = () => (
    <ReactTypingEffect
      className="type-effect"
      text={["HTML5", "CSS3", "Sass", "Bootstrap", "JavaScript", "Node.js", "React", "git version control", "Responsive and Mobile First", "PHP: MySQL", "WordPress", "WooCommerce"]}
      cursor= "_"
      speed= {75}
      eraseDelay= {2500}
    />
);


class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <Element id="home" name="home">
                    <section className="home-section">
                        <div className="intro">
                            <h1>                             
                                Hello my name is <span>Mukhtar Sibai.</span><br /> 
                                I'm a front-end web developer.
                            </h1>
                            <p>
                                I LIKE TO MAKE THINGS THAT MAKE A DIFFERENCE.
                            </p>
                            <div className="typing">
                                <TypingEffect />
                            </div>
                        </div>
                        <a href="#about"><span></span></a>
                        <ScrollDown />
                    </section>
                </Element>
            </React.Fragment>
        );
    }
}

export default Home;
