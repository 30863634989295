import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';
import 'react-toastify/dist/ReactToastify.css';


class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			subject: '',
			message: '',
			errors: {
				name: '',
				email: '',
				subject: '',
				message: '',
			}
		}
	}

	handelChange(event) {
		event.preventDefault();
		const target = event.target;
		const name = target.name;
		const value = target.value;

		this.setState({[name]: value});
	}

	validateMail() {
		let errors = {};
		let formIsValid = true;

		if (!this.state.name || this.state.name.length < 3) {
			errors.name = 'Please enter your name';
			formIsValid = false;
		}
		
		if (!this.state.subject || this.state.subject.length < 3) {
			errors.subject = 'Please enter the subject';
			formIsValid = false;
		}

		if (!this.state.message || this.state.message.length < 3) {
			errors.message = 'Please enter your message';
			formIsValid = false;
		}

		if (!this.state.email) {
			formIsValid = false;
		}

		let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

		if (!pattern.test(this.state.email)) {
			errors.email = 'This is not valid email';
			formIsValid = false;
		}

		this.setState({errors: errors});

		return formIsValid;
	}

	sentMessage(event) {
		event.preventDefault();
		
		if (!this.validateMail()) {
			return;
		}

		var parameters = {
			from_name: this.state.name + '(' + this.state.email + ')',
			to_name: 'mukhtarsibai@gmail.com',
			email: this.state.email,
			subject: this.state.subject,
			message_html: this.state.message
		};

		emailjs.send('gmail', 'portfolio', parameters, 'user_WXN0mo8ZJgaVWyp9gTbjq')
            .then(function(response){ toast.success("Thank You. Your message sent successfuly !", {position: toast.POSITION.TOP_CENTER});},
              function(err) { toast.error(err); });
              
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: '',
        });
	}

	render() { 
		return (
			<div>
				<form
				  id={this.props.id}
				  name={this.props.name}
				  method={this.props.method}
				  action={this.props.action}
				  className="contact-me"
				>
					<Input
					  id="Name"
					  type="text"
					  name="name"
					  className="form-control"
					  required="required"
					  onChange={this.handelChange.bind(this)}
					  value={this.state.name}
					  error={this.state.errors.name}
					/>

					<Input
					  id="Email"
					  type="email"
					  name="email"
					  className="form-control"
					  required="required"
					  onChange={this.handelChange.bind(this)}
					  value={this.state.email}
					  error={this.state.errors.email}
					/>

					<Input
					  id="Subject"
					  type="text"
					  name="subject"
					  className="form-control"
					  required="required"
					  onChange={this.handelChange.bind(this)}
					  value={this.state.subject}
					  error={this.state.errors.subject}
					/>

					<TextArea
                      id="message"
                      type="text"
					  name="message"
					  className="form-control"
					  required="required"
					  rows="8"
					  onChange={this.handelChange.bind(this)}
					  value={this.state.message}
					  error={this.state.errors.message}
					/>

					<Button
					  type="button"
					  name="submit"
					  className="btn"
					  onClick={this.sentMessage.bind(this)}
					/>
				</form>
			</div>
		);
	}
}
 
export default ContactForm;
