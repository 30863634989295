import React, { Component } from 'react';
import { Element } from 'react-scroll';
import projects from './data';


class Portfolio extends Component {

    render() {

        const projectsList =  projects.map((item, i) => {
            return(
                <div className="project" key={i}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <div className="project-image">
                            <img src={require(`../../images/${item.imageName}.png`)} alt="screenshot of the project"></img>
                        </div>
                    </a>
                    <div className="project-description">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <h3 className="project-title">{item.name}</h3>
                            <div className="separator"></div>
                        </a>
                        <p>{item.info}</p>
                    </div>
                </div>
            );
        });
        
        return (
            <React.Fragment>
                <Element id="portfolio" name="portfolio">
                    <section className="portfolio-section">
                        <div className="section-header">
                            <h2>PORTFOLIO</h2>
                            <div className="separator"></div>
                        </div>
                        <div className="section-main">
                            {projectsList}
                        </div>
                    </section>
                </Element>
            </React.Fragment>
        );
    }
}

export default Portfolio;
