import React, { Component } from 'react';


class GoTop extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            intervalId: 0,
            position: false
         }
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            if (window.scrollY > 1700) {
                this.setState({position: true});
            } else {
                this.setState({position: false});
            }
        });
        window.scrollTo(0,0);
    }

    onScrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({intervalId: intervalId});
    }

    renderGoTopButton = () => {
        if (this.state.position) {
            return (
                <button
                  title="Back to top"
                  className="scroll-top"
                  onClick={this.scrollToTop}
                >
                <i className="arrow-up"></i>
              </button>             
            );
        }
    }
    
    render() { 
        return (
            <React.Fragment>
                {this.renderGoTopButton()}
            </React.Fragment>
        );
    }
}
 
export default GoTop;
