const skill = [
    {
        id: "1",
        name: "HTML5",
        imageName: "html",
        period: "2 YEARS",
        percent: "90"
    },

    {
        id: "2",
        name: "CSS3",
        imageName: "css",
        period: "2 YEARS",
        percent: "60"
    },

    {
        id: "3",
        name: "Sass",
        imageName: "sass",
        period: "6 MONTHS",
        percent: "40"
    },

    {
        id: "4",
        name: "CSSnext",
        imageName: "cssnext",
        period: "4 MONTHS",
        percent: "40"
    },

    {
        id: "5",
        name: "Bootstrap",
        imageName: "bootstrap",
        period: "4 MONTHS",
        percent: "30"
    },

    {
        id: "6",
        name: "Javascript",
        imageName: "javascript",
        period: "1 YEAR",
        percent: "50"
    },

    {
        id: "7",
        name: "Node.js",
        imageName: "node",
        period: "1 YEAR",
        percent: "40"
    },

    {
        id: "8",
        name: "React",
        imageName: "react",
        period: "1 YEAR",
        percent: "50"
    },

    {
        id: "9",
        name: "MySQL",
        imageName: "mysql",
        period: "5 MONTHS",
        percent: "30"
    },

    {
        id: "10",
        name: "php",
        imageName: "php",
        period: "5 MONTHS",
        percent: "30"
    },

    {
        id: "11",
        name: "git",
        imageName: "git",
        period: "1 YEAR",
        percent: "60"
    },

    {
        id: "12",
        name: "Gulp",
        imageName: "gulp",
        period: "6 MONTHS",
        percent: "40"
    },

    {
        id: "13",
        name: "WordPress",
        imageName: "wordpress",
        period: "2 YEARS",
        percent: "70"
    },

    {
        id: "14",
        name: "WooCommerce",
        imageName: "woocommerce",
        period: "2 YEARS",
        percent: "70"
    },

    {
        id: "15",
        name: "Photoshop",
        imageName: "photoshop",
        period: "1 YEAR",
        percent: "30"
    },

    {
        id: "16",
        name: "Illustrator",
        imageName: "illustrator",
        period: "1 YEAR",
        percent: "30"
    },
];

export default skill;
