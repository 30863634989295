import React, { Component } from 'react';
import { Element } from 'react-scroll';
import experiences from './data';


class Experience extends Component {
    render() {
        const experienceList = experiences.map((item, i) => {
            return(
                <div className="experience-entry" key={i}>
                    <div className="entry-header">
                        <h3>{item.companyName} <span>{item.period}</span></h3>
                        <h4>{item.position}</h4>
                    </div>
                    <div className="entry-description">
                        <p>{item.info}</p>
                    </div>
                </div>
            );
        }); 
        return (
            <React.Fragment>
                <Element id="experience" name="experience">
                    <section className="experience-section">
                        <div className="section-header">
                            <h2>EXPERIENCE</h2>
                            <div className="separator"></div>
                        </div>
                        <div className="section-main">
                            {experienceList}
                        </div>
                    </section>
                </Element>
            </React.Fragment>
        );
    }
}
 
export default Experience;
