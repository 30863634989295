import React from 'react';
import Header from './Components/Header/Component';
import Home from './Components/Home/Component';
import About from './Components/About/Component';
import Experience from './Components/Experience/Component';
import Skills from './Components/Skills/Component';
import Portfolio from './Components/Portfolio/Component';
import Contact from './Components/Contact/Component';
import Footer from './Components/Footer/Component';
import GoTop from './Components/ScrollUp/Component';
import './css/style.css';

function PortfolioApp() {
  return (
    <div className="PortfolioApp">
      <Header />
      <Home />
      <About />
      <Experience />
      <Skills />
      <Portfolio />
      <Contact />
      <Footer />
      <GoTop scrollStepInPx="50" delayInMs="5" />
    </div>
  );
}

export default PortfolioApp;
